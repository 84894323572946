import { useState } from 'react';
import data from '../assets/data.json';
import { Box, Typography } from '@mui/material';
import QuestionMarkSVG from './QuestionMarkSVG';
import Overlay from '../../VestmapViewer/Overlay';
import { Star } from 'lucide-react';
import BlockNameHeading from './BlockNameHeading';

export default function NearestThreeSchoolsBlock({ schools }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  // Check if section info is available in data.json
  const sectionInfoAvailable = data['Nearest 3 Schools'];

  const style = {
    colorBox: {
      height: '1rem',
      width: '1rem',
    },
    schoolBox: {
      display: 'flex',
      gap: '1.25rem',
      alignItems: 'center',
    },
    schoolTitle: {
      fontFamily: 'Lato',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '20px',
      textAlign: 'left',
      textDecoration: 'underline',
    },
  };

  const colors = ['#FF5733', '#33FF57', '#3357FF'];

  if (!schools) return null;
  console.log({ schools });

  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1.25rem',
        paddingX: '3.5rem',
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          paddingX: '1rem',
          paddingY: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '.5rem',
            alignItems: 'center',
          }}
        >
          <BlockNameHeading title='Nearest Three Schools' />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          {schools.map((school, index) => (
            <Box key={index} sx={style.schoolBox}>
              <Box
                sx={[
                  style.colorBox,
                  {
                    backgroundColor: colors[index] || '#000000',
                  },
                ]}
              />
              <Typography sx={style.schoolTitle}>
                {school.name}
                {school?.ranking && school.ranking?.rankStars && (
                  <>
                    {' ('}
                    {[...Array(school.ranking.rankStars)].map((_, i) => (
                      <Star key={i} size={16} fill='#FFD700' stroke='#FFD700' />
                    ))}
                    {')'}
                  </>
                )}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      {sectionInfoAvailable && (
        <Box
          onClick={() => {
            handleQuestionMarkClick('Nearest 3 Schools');
            setIsOverlayVisible(true);
          }}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <QuestionMarkSVG />
        </Box>
      )}

      {activeOverlay === 'Nearest 3 Schools' && (
        <div
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '16rem',
            zIndex: '1000',
          }}
        >
          <Overlay
            isVisible={isOverlayVisible}
            onClose={() => {
              setIsOverlayVisible(false);
              handleCloseOverlay();
            }}
            keyElement={'Nearest 3 Schools'}
          />
        </div>
      )}
    </Box>
  );
}
