import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Hamburger from '../Hamburger';
import ErrorPopUp from '../pages/BulkVestmaps/ErrorPopUp';
import styles from './viewer.module.css';

import Crime from './Crime';
import Demographics from './Demographics';
import Expansion from './Expansion';
import HPI from './HPI';
import Income from './Income';
import MSA from './MSA';
import Neighborhood from './Neighborhood';
import Rent from './Rent';
import Schools from './Schools';

import { ReactComponent as DemoIconDark } from '@/../../public/demoIconDark.svg';

import { ReactComponent as IncomeIconDark } from '@/../../public/incomeIconDark.svg';

import { ReactComponent as HPIIconDark } from '@/../../public/hpiIconDark.svg';

import { ReactComponent as SchoolsIconDark } from '@/../../public/schoolIconDark.svg';

import { ReactComponent as CrimeIconDark } from '@/../../public/crimeIconDark.svg';

import { ReactComponent as ExpansionIconDark } from '@/../../public/expansionIconDark.svg';

import { ReactComponent as RentIconDark } from '@/../../public/rentIconDark.svg';

import { ReactComponent as NeighborhoodIconDark } from '@/../../public/neighborhoodIconDark.svg';

import { ReactComponent as MSAIconDark } from '@/../../public/msaIconDark.svg';

import { ReactComponent as AIIconDark } from '@/../../public/flaskDark.svg';

import HeaderImage from './pdf_img/vestmap_logo.png';

import CrimeStats1 from './pdf_img/crime/crime1.png';
import CrimeStats2 from './pdf_img/crime/crime2.png';
import CrimeStats3 from './pdf_img/crime/crime3.png';
import CrimeStats4 from './pdf_img/crime/crime4.png';
import CrimeStats5 from './pdf_img/crime/crime5.png';
import CrimeStats6 from './pdf_img/crime/crime6.png';

import ExpansionStats1 from './pdf_img/expension/expension1.png';
import ExpansionStats2 from './pdf_img/expension/expension2.png';
import ExpansionStats3 from './pdf_img/expension/expension3.png';
import ExpansionStats4 from './pdf_img/expension/expension4.png';
import ExpansionStats5 from './pdf_img/expension/expension5.png';
import ExpansionStats6 from './pdf_img/expension/expension6.png';
import ExpansionStats7 from './pdf_img/expension/expension7.png';

import IncomeStats1 from './pdf_img/income/income1.png';
import IncomeStats2 from './pdf_img/income/income2.png';
import IncomeStats3 from './pdf_img/income/income3.png';
import IncomeStats4 from './pdf_img/income/income4.png';
import IncomeStats5 from './pdf_img/income/income5.png';
import IncomeStats6 from './pdf_img/income/income6.png';

import NeighborhoodStats1 from './pdf_img/neighborhood/neighborhood1.png';
import NeighborhoodStats2 from './pdf_img/neighborhood/neighborhood2.png';
import NeighborhoodStats3 from './pdf_img/neighborhood/neighborhood3.png';
import NeighborhoodStats4 from './pdf_img/neighborhood/neighborhood4.png';
import NeighborhoodStats5 from './pdf_img/neighborhood/neighborhood5.png';
import NeighborhoodStats6 from './pdf_img/neighborhood/neighborhood6.png';
import NeighborhoodStats7 from './pdf_img/neighborhood/neighborhood7.png';
import NeighborhoodStats8 from './pdf_img/neighborhood/neighborhood8.png';

import { useAuth } from '../../AuthProvider';
import VestmapContext from '../contexts/VestmapContext';
import AiAnalysis from './AiAnalysis';
import Sidebar from './Sidebar';

const VestmapViewer = ({
  open,
  setOpen,
  vestmapData,
  openSnackbar,
  modifyVestmap,
  viewerState = state => {},
}) => {
  console.log('OLD VIEWER CALLED');

  const assets = {
    CrimeStats1,
    CrimeStats2,
    CrimeStats3,
    CrimeStats4,
    CrimeStats5,
    CrimeStats6,
    ExpansionStats1,
    ExpansionStats2,
    ExpansionStats3,
    ExpansionStats4,
    ExpansionStats5,
    ExpansionStats6,
    ExpansionStats7,
    IncomeStats1,
    IncomeStats2,
    IncomeStats3,
    IncomeStats4,
    IncomeStats5,
    IncomeStats6,
    NeighborhoodStats1,
    NeighborhoodStats2,
    NeighborhoodStats3,
    NeighborhoodStats4,
    NeighborhoodStats5,
    NeighborhoodStats6,
    NeighborhoodStats7,
    NeighborhoodStats8,
  };

  const { getAccessToken } = useAuth();

  const [vestmap, setVestmap] = useState(vestmapData || {});
  const [arcgisToken, setArcgisToken] = useState(null); // REMOVE
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [generatingPro, setGeneratingPro] = useState(false);
  const [fetchFromSidebar, setFetchFromSidebar] = useState(false);
  // this flag is used to check if the analysis is completed or not
  const [analysisCompletionFlag, setAnalysisCompletionFlag] = useState(() => {
    if (vestmapData?.ai_analysis) {
      return true;
    }
    return false;
  });
  const [analysisLoader, setAnalysisLoader] = useState(false);
  const [analysisError, setAnalysisError] = useState(false);

  const [pdf, setPdf] = useState(
    vestmapData?.assets?.pdf || vestmapData?.pdf || null,
  );
  const [proReport, setProReport] = useState(
    vestmapData?.assets?.pro_report_path ||
      vestmapData?.pro_report_path ||
      null,
  );

  const [limitError, setLimitError] = useState(false);
  const [errorString, setErrorString] = useState('');

  const [currLocation, setCurrLocation] = useState(null);

  const accordionRefs = useRef([]);
  const [updateKey, setUpdateKey] = useState(0);

  async function getToken() {
    try {
      console.log('Getting Access Token from server');
      const accessToken = await getAccessToken();
      const url = process.env.REACT_APP_NODE_URL + '/admin/token';
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) throw new Error('Response error');

      const data = await response.json();
      if (data?.token) {
        console.log('Token received from server');
        setArcgisToken(data.token.access_token);
      }
      throw new Error('Token not found');
    } catch (error) {
      console.error('Error getting token');
      console.error(error);
    }
  }

  useEffect(() => {
    if (
      vestmapData?.assets?.maps &&
      Object.keys(vestmapData.assets.maps).length === 9
    ) {
      console.log('vestmapData.assets.maps has 9 keys');
    } else {
      console.log('vestmapData.assets.maps does not have 9 keys');
      getToken();
    }
  }, []);

  useEffect(() => {
    console.log({ accordionRefs });
  }, [accordionRefs]);

  const generatePro = async () => {
    if (generatingPro) return;
    setGeneratingPro(true);
    try {
      console.log('[Pro Report PDF Generation Started]');
      const url = process.env.REACT_APP_NODE_URL + `/report/request-pro-report`;

      const body = {
        userID: vestmap.MongodbUserID,
        address: vestmap.address_info.address,
        vestmapID: vestmap._id,
        bedrooms: +vestmap.bedrooms || 1,
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(body),
      });

      if (response.status === 403) {
        setErrorString('Pro reports Limit Exceeded');
        setLimitError(true);
        return;
      } else if (response.status === 401) {
        setErrorString('Your plan has expired.');
        setLimitError(true);
        return;
      } else if (response.status === 203) {
        const data = await response.json();
        const { proReportPath } = data;

        setVestmap(prev => {
          const asset = prev?.assets || {};
          return {
            ...prev,
            assets: { ...asset, pro_report_path: proReportPath },
          };
        });

        setProReport(prev => {
          return proReportPath;
        });

        openSnackbar('success');
        return;
      }

      if (!response.ok)
        throw new Error('Something went wrong. Please try again.');

      const data = await response.json();
      const { proReportPath } = data;

      setVestmap(prev => {
        const asset = prev.assets || {};
        return {
          ...prev,
          assets: { ...asset, pro_report_path: proReportPath },
        };
      });

      setProReport(prev => {
        return proReportPath;
      });

      if (localStorage) {
        const user = JSON.parse(localStorage.getItem('mongodbUser'));
        user.pro_searches_remaining -= 1;
        localStorage.setItem('mongodbUser', JSON.stringify(user));
      }

      openSnackbar('success');
    } catch (error) {
      console.log('Error happened here');
      setGeneratingPro(false);

      console.log({ error });
      openSnackbar('pro');
    } finally {
      setGeneratingPro(false);
    }
  };

  const viewPro = () => {
    if (generatingPro) return;

    window.open(proReport, '_blank');
  };

  const viewPDF = async () => {
    if (generatingPDF) return;

    if (pdf.includes('googleapis')) {
      window.open(pdf, '_blank');
      return;
    }

    const path = 'https://oldapp.vestmap.com/pdfs/' + pdf;
    window.open(path, '_blank');
  };

  const generatePDF = async () => {
    if (generatingPDF) return;
    setGeneratingPDF(true);

    try {
      console.log('[PDF Generation Started]');

      const url = process.env.REACT_APP_NODE_URL + `/pdf/request-pdf`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({ vestmap_json: vestmap }),
      });

      if (response.status === 401) {
        setErrorString('Your plan has expired.');
        setLimitError(true);
        return;
      } else if (response.status === 203) {
        // 203 means it already exists
        const data = await response.json();
        const { link } = data;

        setVestmap(prev => {
          const asset = prev?.assets || {};
          return { ...prev, assets: { ...asset, pdf: link } };
        });

        setPdf(prev => {
          return link;
        });

        openSnackbar('success');
        return;
      }

      const data = await response.json();

      if (!data.status) {
        console.log('Error happened here');
        console.log({ data });
        throw new Error('Something went wrong. Please try again.');
      }
      console.log({ vestmap });

      setVestmap(prev => {
        const asset = prev?.assets || {};
        return { ...prev, assets: { ...asset, pdf: data?.link } };
      });

      setPdf(prev => {
        return data?.link;
      });

      openSnackbar('success');
    } catch (error) {
      console.log('Error happened here');
      setGeneratingPDF(false);

      console.log({ error });
      openSnackbar('pdf');
    } finally {
      setGeneratingPDF(false);
    }
  };

  useEffect(() => {
    console.log({ pdf, proReport });
    if (pdf) {
      console.log('PDF is present');
      const asset = vestmap?.assets || {};
      modifyVestmap({ ...vestmap, assets: { ...asset, pdf } });
    }
    if (proReport) {
      console.log('Pro Report is present');
      const asset = vestmap?.assets || {};
      modifyVestmap({
        ...vestmap,
        assets: { ...asset, pro_report_path: proReport },
      });
    }
  }, [pdf, proReport]);

  useEffect(() => {
    setVestmap(vestmapData);
  }, [vestmapData]);

  useEffect(() => {
    setPdf(vestmap?.assets?.pdf || vestmap?.pdf);
    setProReport(vestmap?.assets?.pro_report_path || vestmap?.pro_report_path);

    // Write a function to check if the map images exist in the assets
    // if not, then fetch them from the server. and also save them in the db.
    const assets = vestmap?.assets || {};
    if (assets.maps) {
      return;
    } else {
      console.log('Maps do not exist');
      fetchMaps(vestmap._id);
    }
  }, []);

  useEffect(() => {
    setPdf(vestmap?.assets?.pdf || vestmap?.pdf);
    setProReport(vestmap?.assets?.pro_report_path || vestmap?.pro_report_path);
  }, [vestmap]);

  // * This function checks to see if the maps are present in the assets.
  // * This potential bug sometimes arrises because of complications during migrations
  const fetchMaps = async id => {
    try {
      console.log('Fetching Maps');
      const url = process.env.REACT_APP_NODE_URL + `/migrate-maps/` + id;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getAccessToken()}`,
        },
      });
      const data = await response.json();
      console.log({ data });
      if (data.status) {
        const { maps } = data;

        modifyVestmap(prev => {
          return { ...prev, assets: { ...prev.assets, maps: maps } };
        });

        setVestmap(prev => {
          return { ...prev, assets: { ...prev.assets, maps: maps } };
        });
        return;
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const handleClose = () => {
    if (pdf) modifyVestmap({ ...vestmap, assets: { ...vestmap.assets, pdf } });
    if (proReport)
      modifyVestmap({
        ...vestmap,
        assets: { ...vestmap.assets, pro_report_path: proReport },
      });

    // clear all states before closing
    setVestmap({});
    setGeneratingPDF(false);
    setGeneratingPro(false);

    setOpen(false);
    viewerState(false);
  };
  function closeLimitError() {
    setLimitError(false);
  }

  function setCurrentLocation(location) {
    setCurrLocation(location);
    setUpdateKey(prev => prev + 1);
  }

  useEffect(() => {
    if (currLocation && accordionRefs.current[currLocation]) {
      accordionRefs.current[currLocation].scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [currLocation, updateKey]);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  useEffect(() => {}, [sidebarOpen]);
  const [currentSection, setCurrentSection] = useState(null);

  const handleSectionChange = sectionId => {
    setCurrentSection(sectionId);
  };

  useEffect(() => {
    setSidebarOpen(false);
  }, [currentSection]);

  return (
    <VestmapContext.Provider
      value={{
        vestmap,
        setVestmap,
        modifyVestmap,
        analysisCompletionFlag,
        setAnalysisCompletionFlag,
        analysisLoader,
        setAnalysisLoader,
        analysisError,
        fetchFromSidebar,
        setFetchFromSidebar,
        setAnalysisError,
        arcgisToken,
      }}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        scroll='body'
        fullWidth={true}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        className={styles.dialog}
        PaperProps={{
          sx: {
            boxSizing: 'border-box',
            borderRadius: {
              sm: '20px',
              xs: '0px',
            },
            overflow: {
              xs: sidebarOpen && 'hidden',
            },
          },
        }}
        fullScreen={true}
        id='viewerDialogBox'
        // only for small screens
        onClick={() => {
          if (sidebarOpen) {
            setSidebarOpen(false);
          }
        }}
      >
        {limitError && (
          <ErrorPopUp closeLimitError={closeLimitError} error={errorString} />
        )}

        <DialogContent className={styles.dialogContent}>
          <Box
            sx={{
              display: {
                xs: 'flex',
                sm: 'grid',
              },
              gridTemplateColumns: {
                xs: '100%',
                sm: '22% 78%',
                md: '18% 82%',
              },
              width: '100%',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: {
                  xs: 'none',
                  sm: 'block',
                },
                position: 'sticky',
                top: 0,
                height: '100vh',
                overflowY: 'auto',
              }}
            >
              <Sidebar
                generatePDFFunc={generatePDF}
                generateProFunc={generatePro}
                pdfStatus={pdf}
                proReportStatus={proReport}
                vestmap={vestmap}
                viewPDFFunc={viewPDF}
                viewProFunc={viewPro}
                setCurrLocation={setCurrentLocation}
                currLocation={currentSection}
              />
            </Box>
            <Box
              sx={{
                display: {
                  xs: 'block',
                  sm: 'none',
                },

                position: 'absolute',
                left: 0,
                top: 0,
                zIndex: 100,
                width: '60%',
                height: '100%',
                overflowY: 'auto',
                transition: 'transform 0.3s',
                transform: {
                  xs: sidebarOpen ? 'translateX(0)' : 'translateX(-100%)',
                  sm: 'none',
                },
              }}
            >
              <Sidebar
                generatePDFFunc={generatePDF}
                generateProFunc={generatePro}
                pdfStatus={pdf}
                proReportStatus={proReport}
                vestmap={vestmap}
                viewPDFFunc={viewPDF}
                viewProFunc={viewPro}
                setCurrLocation={setCurrentLocation}
                currLocation={currentSection}
              />
            </Box>

            <Box
              sx={{
                overflowY: {
                  sm: 'auto',
                  xs: 'none',
                },
                height: {
                  sm: '100vh',
                },

                scrollbarWidth: 'none',
              }}
            >
              <DialogTitle
                id='scroll-dialog-title'
                className={styles.dialogHeader}
                sx={{
                  backgroundColor: '#F9F9F9',
                }}
              >
                <IconButton
                  aria-label='close'
                  onClick={setSidebarOpen.bind(this, !sidebarOpen)}
                  sx={{
                    position: 'absolute',
                    display: { xs: 'block', sm: 'none' },
                    left: 11,
                    top: 11,
                    height: '40px',
                    width: '40px',
                    zIndex: 1001,
                    color: theme => theme.palette.grey[500],
                  }}
                >
                  <Hamburger isOpen={sidebarOpen} headerFlag={false} />
                </IconButton>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '40px',
                    padding: '24px',
                  }}
                >
                  <Box
                    sx={{
                      width: {
                        sm: '100%',
                        xs: '220px',
                      },
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={HeaderImage} alt='' />
                  </Box>
                  <div className={styles.dialogHeaderChild}>
                    <h2>{vestmap?.address_info?.address || ''}</h2>
                  </div>
                </Box>

                <IconButton
                  aria-label='close'
                  onClick={handleClose}
                  sx={{
                    position: 'fixed',
                    right: 11,
                    top: 11,
                    zIndex: 4,
                    height: '40px',
                    width: '40px',
                    color: theme => theme.palette.grey[500],
                  }}
                >
                  <Hamburger isOpen={true} headerFlag={false} />
                </IconButton>
              </DialogTitle>
              <VestmapAccordion
                vestmap={vestmap}
                assets={assets}
                selectedGroup={currLocation}
                accordionRefs={accordionRefs}
                onSectionChange={handleSectionChange}
                modifyVestmap={modifyVestmap}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Box
            sx={{
              width: {
                sm: '79.7%',
                xs: '100%',
              },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: {
                sm: '18px',
                xs: '14px',
              },

              gap: {
                sm: '16px',
                xs: '12px',
              },
            }}
          >
            <Box
              sx={{
                color: '#84939C',
                fontFamily: 'Lato',
                fontWeight: '700',
              }}
            >
              Custom Report Generated by
            </Box>
            <Box
              sx={{
                width: {
                  sm: '200px',
                  xs: '150px',
                },
                height: {
                  sm: '30px',
                  xs: '27px',
                },
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img src={HeaderImage} alt='Vestmap Logo' />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </VestmapContext.Provider>
  );
};

const VestmapAccordion = ({
  vestmap,
  assets,
  selectedGroup,
  accordionRefs,
  onSectionChange,
  modifyVestmap,
}) => {
  const required = [
    'ai_analysis',
    'demographic_group',
    'income_block_tract',
    'HPI_special',
    'schools',
    'crime',
    'expansion',
    'rent',
    'neighbourhood',
    'metropolitan_statistical_area',
  ];

  const debounceTimeout = useRef(null);

  useEffect(() => {
    const debouncedObserverCallback = entries => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          console.log('Section in view:', sectionId);
          onSectionChange(sectionId);
        }
      }
    };

    const observer = new IntersectionObserver(
      entries => {
        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(
          () => debouncedObserverCallback(entries),
          100,
        );
      },
      {
        threshold: [0.3, 0.4, 0.5, 0.8, 1],
        root: null,
      },
    );

    required.forEach(key => {
      const section = accordionRefs.current[key];
      if (section) {
        observer.observe(section);
      } else {
        console.error(`Section not found: ${key}`);
      }
    });

    return () => {
      required.forEach(key => {
        const section = accordionRefs.current[key];
        if (section) observer.unobserve(section);
      });
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [accordionRefs, onSectionChange]);

  const accordianStyles = {
    svgDimensions: {
      width: '35px',
      height: '35px',
    },
  };

  if (!vestmap) return null;

  return (
    <Box
      sx={{
        padding: {
          xs: '0',
          sm: '0px 24px 20px 24px',
        },
      }}
    >
      {required.map((key, Index) => {
        if (
          !vestmap?.ai_analysis &&
          key === 'ai_analysis' &&
          selectedGroup !== 'ai_analysis'
        ) {
          return null;
        }

        if (!vestmap?.ai_analysis && key === 'ai_analysis') {
          return null;
        }

        if (!vestmap?.demographic_group && key === 'demographic_group') {
          return null;
        }

        if (!vestmap?.income_block_tract && key === 'income_block_tract') {
          return null;
        }

        if (
          !(
            vestmap?.hpi_tracts ||
            vestmap?.hpi_zips ||
            vestmap?.hpi_counties
          ) &&
          key === 'HPI_special'
        ) {
          return null;
        }

        if (!vestmap?.schools && key === 'schools') {
          return null;
        }

        if (!vestmap?.crime && key === 'crime') {
          return null;
        }

        if (!vestmap?.expansion && key === 'expansion') {
          return null;
        }

        if (!vestmap?.rent && key === 'rent') {
          return null;
        }

        if (!vestmap?.neighborhood && key === 'neighbourhood') {
          return null;
        }

        if (
          !vestmap?.metropolitan_statistical_area &&
          key === 'metropolitan_statistical_area'
        ) {
          return null;
        }

        return (
          <Accordion
            key={Index}
            defaultExpanded={true}
            TransitionProps={{ unmountOnExit: true }}
            sx={{
              borderRadius: '16px !important',
              boxShadow: '5px 20px 30px 0px #E5E5E5B2',
              scrollbarWidth: 'none',
            }}
            ref={el => (accordionRefs.current[key] = el)}
            id={key}
          >
            <AccordionSummary
              id={`panel${Index}bh-header`}
              className={styles.accordionSummary}
              sx={{
                pointerEvents: 'none',
              }}
            >
              <div
                style={{
                  marginRight: '1rem',
                }}
              >
                {key === 'ai_analysis' && (
                  <AIIconDark style={accordianStyles.svgDimensions} />
                )}

                {key === 'metropolitan_statistical_area' &&
                  vestmap?.metropolitan_statistical_area && (
                    <MSAIconDark style={accordianStyles.svgDimensions} />
                  )}
                {key === 'demographic_group' && vestmap?.demographic_group && (
                  <DemoIconDark style={accordianStyles.svgDimensions} />
                )}
                {key === 'income_block_tract' &&
                  vestmap?.income_block_tract && (
                    <IncomeIconDark style={accordianStyles.svgDimensions} />
                  )}
                {key === 'schools' && vestmap?.schools && (
                  <SchoolsIconDark style={accordianStyles.svgDimensions} />
                )}
                {key === 'crime' && vestmap?.crime && (
                  <CrimeIconDark style={accordianStyles.svgDimensions} />
                )}
                {key === 'expansion' && vestmap?.expansion && (
                  <ExpansionIconDark style={accordianStyles.svgDimensions} />
                )}
                {key === 'rent' && vestmap?.rent && (
                  <RentIconDark style={accordianStyles.svgDimensions} />
                )}
                {key === 'neighbourhood' && vestmap?.neighborhood && (
                  <NeighborhoodIconDark style={accordianStyles.svgDimensions} />
                )}
                {key === 'HPI_special' &&
                  Object.keys(vestmap).some(key =>
                    key.toLowerCase().includes('hpi'),
                  ) && <HPIIconDark style={accordianStyles.svgDimensions} />}
              </div>
              <Typography
                sx={{
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontWeight: '700',
                  lineHeight: '24px',
                  letterSpacing: '0.15px',
                  textAlign: 'left',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {key === 'ai_analysis' && vestmap?.ai_analysis && 'AI Analysis'}
                {key === 'metropolitan_statistical_area' &&
                  vestmap?.metropolitan_statistical_area &&
                  'Metropolitan Statistical Area'}
                {key === 'demographic_group' &&
                  vestmap?.demographic_group &&
                  'Demographic Group'}
                {key === 'income_block_tract' &&
                  vestmap?.income_block_tract &&
                  'Income Block Tract'}
                {key === 'schools' && vestmap?.schools && 'Schools'}
                {key === 'crime' && vestmap?.crime && 'Crime'}
                {key === 'expansion' && vestmap?.expansion && 'Expansion'}
                {key === 'rent' && vestmap?.rent && 'Rent'}
                {key === 'neighbourhood' &&
                  vestmap?.neighborhood &&
                  'Neighborhood'}
                {key === 'HPI_special' &&
                  Object.keys(vestmap).some(key =>
                    key.toLowerCase().includes('hpi'),
                  ) &&
                  'House Price Index (Tract/Zip/County)'}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PickComponent
                selector={key}
                vestmap={vestmap}
                assets={assets}
                modifyVestmap={modifyVestmap}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

const PickComponent = ({ selector, vestmap, assets, modifyVestmap }) => {
  let currentAssets = {};
  switch (selector) {
    case 'ai_analysis':
      return <AiAnalysis />;

    case 'demographic_group':
      if (vestmap?.demographic_group)
        return (
          <Demographics
            demographics={vestmap[selector]}
            map={
              vestmap.assets?.maps?.demographics_map ||
              vestmap?.maps?.demographics_map
            }
            address_info={vestmap['address_info']}
          />
        );

      break;
    case 'income_block_tract':
      currentAssets = {
        IncomeStats1: assets.IncomeStats1,
        IncomeStats2: assets.IncomeStats2,
        IncomeStats3: assets.IncomeStats3,
        IncomeStats4: assets.IncomeStats4,
        IncomeStats5: assets.IncomeStats5,
        IncomeStats6: assets.IncomeStats6,
      };

      if (vestmap?.income_block_tract)
        return (
          <Income
            income={vestmap[selector]}
            map={vestmap.assets?.maps?.income_map || vestmap?.maps?.income_map}
            address_info={vestmap['address_info']}
            {...currentAssets}
          />
        );

      break;
    case 'schools':
      if (vestmap?.schools)
        return (
          <Schools
            schools={vestmap[selector]}
            map={
              vestmap.assets?.maps?.school_map ||
              vestmap?.maps?.school_map ||
              vestmap.assets?.maps?.schools_map
            }
            address_info={vestmap['address_info']}
            metropolitan_statistical_area={
              vestmap['metropolitan_statistical_area']
            }
          />
        );
      break;
    case 'expansion':
      currentAssets = {
        ExpansionStats1: assets.ExpansionStats1,
        ExpansionStats2: assets.ExpansionStats2,
        ExpansionStats3: assets.ExpansionStats3,
        ExpansionStats4: assets.ExpansionStats4,
        ExpansionStats5: assets.ExpansionStats5,
        ExpansionStats6: assets.ExpansionStats6,
        ExpansionStats7: assets.ExpansionStats7,
      };
      if (vestmap?.expansion)
        return (
          <Expansion
            expansion={vestmap[selector]}
            map={
              vestmap.assets?.maps?.expansion_map ||
              vestmap?.maps?.expansion_map
            }
            address_info={vestmap['address_info']}
            {...currentAssets}
          />
        );
      break;
    case 'crime':
      currentAssets = {
        CrimeStats1: assets.CrimeStats1,
        CrimeStats2: assets.CrimeStats2,
        CrimeStats3: assets.CrimeStats3,
        CrimeStats4: assets.CrimeStats4,
        CrimeStats5: assets.CrimeStats5,
        CrimeStats6: assets.CrimeStats6,
      };
      if (vestmap?.crime)
        return (
          <Crime
            crime={vestmap[selector]}
            map={vestmap.assets?.maps?.crime_map || vestmap?.maps?.crime_map}
            address_info={vestmap['address_info']}
            {...currentAssets}
          />
        );
      break;
    case 'neighbourhood':
      currentAssets = {
        NeighborhoodStats1: assets.NeighborhoodStats1,
        NeighborhoodStats2: assets.NeighborhoodStats2,
        NeighborhoodStats3: assets.NeighborhoodStats3,
        NeighborhoodStats4: assets.NeighborhoodStats4,
        NeighborhoodStats5: assets.NeighborhoodStats5,
        NeighborhoodStats6: assets.NeighborhoodStats6,
        NeighborhoodStats7: assets.NeighborhoodStats7,
        NeighborhoodStats8: assets.NeighborhoodStats8,
      };
      if (vestmap?.neighborhood)
        return (
          <Neighborhood
            neighborhood={vestmap['neighborhood']}
            map={
              vestmap.assets?.maps?.neighborhood_map ||
              vestmap?.maps?.neighborhood_map
            }
            address_info={vestmap['address_info']}
            {...currentAssets}
          />
        );
      break;
    case 'metropolitan_statistical_area':
      if (vestmap?.metropolitan_statistical_area)
        return (
          <MSA
            msa={vestmap[selector]}
            map={vestmap.assets?.maps?.msa_map || vestmap?.maps?.msa_map}
            address_info={vestmap['address_info']}
          />
        );
      break;
    case 'rent':
      if (vestmap?.rent)
        return <Rent rent={vestmap[selector]} bedrooms={vestmap.bedrooms} />;
      break;
    case 'HPI_special':
      const { hpi_tracts, hpi_zips, hpi_counties } = vestmap;
      if (Object.keys(vestmap).some(key => key.toLowerCase().includes('hpi')))
        return (
          <HPI
            zips={hpi_zips}
            tracts={hpi_tracts}
            counties={hpi_counties}
            address_info={vestmap['address_info']}
            map={vestmap.assets?.maps?.hpi_map || vestmap?.maps?.hpi_map}
          />
        );

      break;
    default:
      return <div>{selector}</div>;
      break;
  }
};

export default VestmapViewer;
