import { Box, Typography } from '@mui/material';
import QuestionMarkSVG from './QuestionMarkSVG';
import React, { useState } from 'react';
import Overlay from '../../VestmapViewer/Overlay';
import BlockNameHeading from './BlockNameHeading';
import { formatNumber } from '../utils';

function NationalAverageCrimeIndex({ sectionBlock }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  if (!sectionBlock) return null;
  const styles = {
    text: {
      fontFamily: 'Lato',
      lineHeight: '1.5rem',
      textAlign: 'center',
    },
  };

  const formatMultiple = value => {
    if (!value) return 'N/A';
    const value2 = value / 100;
    return `${value2}`;
  };
  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1.25rem',
        paddingX: '1rem',
        borderRadius: '1rem',
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '8rem',
        position: 'relative',
      }}
    >
      <BlockNameHeading title={sectionBlock?.group || ''} />

      <Typography
        sx={[
          styles.text,
          {
            fontSize: '1.4rem',
            fontWeight: 900,
          },
        ]}
      >
        {sectionBlock?.key === 'crime' &&
          (sectionBlock?.value
            ? sectionBlock?.outfields
                .map(outfield => {
                  if (!sectionBlock.value[outfield]) {
                    return 'N/A';
                  }
                  const prefix =
                    sectionBlock?.prefix === 'None'
                      ? ''
                      : sectionBlock?.prefix || '';
                  const suffix =
                    sectionBlock?.suffix === 'None'
                      ? ''
                      : sectionBlock?.suffix || '';
                  return `${prefix}${isNaN(sectionBlock.value[outfield]) ? formatNumber(sectionBlock.value[outfield]) : formatMultiple(sectionBlock.value[outfield])}${suffix}`;
                })
                .filter(value => value !== 'N/A')
                .join(' ')
            : 'N/A')}

        {sectionBlock?.key !== 'crime' &&
          (sectionBlock?.value
            ? sectionBlock?.outfields
                .map((outfield, index) => {
                  if (!sectionBlock.value[outfield]) {
                    return 'N/A';
                  }
                  const prefix =
                    sectionBlock?.prefix === 'None'
                      ? ''
                      : sectionBlock?.prefix || '';
                  const suffix =
                    sectionBlock?.suffix === 'None'
                      ? ''
                      : sectionBlock?.suffix || '';

                  return `${prefix}${formatNumber(sectionBlock.value[outfield])}${suffix}`;
                })
                .join(' ')
            : 'N/A')}
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Lato',
          lineHeight: '1.5rem',
          textAlign: 'center',
          fontSize: '1rem',
          fontWeight: 500,
        }}
      >
        National Average
      </Typography>

      <Typography
        sx={[
          styles.text,
          {
            fontSize: '1.1rem',
            fontWeight: 500,
            color: '#39585C',
          },
        ]}
      >
        {sectionBlock.title}
      </Typography>

      <Box
        onClick={() => {
          console.log('I am clicked');
          handleQuestionMarkClick(sectionBlock?.title);
          setIsOverlayVisible(true);
        }}
        sx={{
          cursor: 'pointer',
        }}
      >
        <QuestionMarkSVG />
      </Box>

      {activeOverlay === sectionBlock?.title && (
        <div
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '16rem',
            zIndex: '1000',
          }}
        >
          <Overlay
            isVisible={isOverlayVisible}
            onClose={() => {
              setIsOverlayVisible(false);
              handleCloseOverlay();
            }}
            keyElement={sectionBlock?.title}
          />
        </div>
      )}

      {sectionBlock?.tooltip && <QuestionMarkSVG />}
    </Box>
  );
}

export default NationalAverageCrimeIndex;
