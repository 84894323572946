import React, { useState, useEffect } from 'react';
import { Edit, Save } from '@mui/icons-material';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Skeleton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import MapEditor from './MapEditor';
import RealTimeView from './RealTimeView';
import { useAuth } from '../../../AuthProvider';

export default function AdminPage() {
  const { getAccessToken, user } = useAuth();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [searchResult, setSearchResult] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [editableRow, setEditableRow] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  // for different sections of admin panel
  const [currentSection, setCurrentSection] = useState('users'); // users, maps, real-time-view

  useEffect(() => {
    checkAdminStatus();
  }, []);

  useEffect(() => {
    console.log('Fetching users');
    getUsers();
  }, [page]);

  useEffect(() => {
    console.log('Loading changed, ', loading);
  }, [loading]);

  async function getUsers() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NODE_URL}/admin/get-users?page=${page}&pageSize=${pageSize}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getAccessToken()}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Data:', data);

      const users = data?.users?.map(user => {
        return {
          ...user,
          bulk_searches_remaining: user.bulk_searches_remaining || 0,
          pro_searches_remaining: user.pro_searches_remaining || 0,
        };
      });

      setSearchResult(users);
      setTotalPages(data.totalPages);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }

  async function searchUsers() {
    try {
      console.log('Searching users, ', search, page, pageSize);
      const response = await fetch(
        `${process.env.REACT_APP_NODE_URL}/admin/search-users?search=${search}&page=${page}&pageSize=${pageSize}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getAccessToken()}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const users = data?.users?.map(user => {
        return {
          ...user,
          bulk_searches_remaining: user.bulk_searches_remaining || 0,
          pro_searches_remaining: user.pro_searches_remaining || 0,
        };
      });

      setSearchResult(users);
      setTotalPages(data.totalPages);
      setLoading(false);
    } catch (error) {
      console.error('Error searching users:', error);
    }
  }

  function handleSearchChange(event) {
    setSearch(event.target.value);
  }

  function handleSearchSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setPage(1); // Reset page number when performing a new search
    searchUsers();
  }

  function handleEditClick(user) {
    setEditableRow(user._id);
    setEditedData({
      bulk_searches_remaining: user.bulk_searches_remaining,
      pro_searches_remaining: user.pro_searches_remaining,
    });
  }

  async function checkAdminStatus() {
    try {
      const url = `${process.env.REACT_APP_NODE_URL}/admin/check-admin`;

      console.log({ user });
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({
          email: user.Email,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      console.log({ data });

      if (data.isAdmin) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } catch (error) {
      console.error('Error checking admin status:', error);
    }
  }

  function handleSaveClick(userId) {
    if (
      editedData.bulk_searches_remaining < 0 ||
      isNaN(editedData.bulk_searches_remaining) ||
      editedData.pro_searches_remaining < 0 ||
      isNaN(editedData.pro_searches_remaining)
    ) {
      console.log('Invalid data');
      return;
    }

    const updatedUsers = searchResult.map(user => {
      if (user._id === userId) {
        return {
          ...user,
          bulk_searches_remaining: editedData.bulk_searches_remaining,
          pro_searches_remaining: editedData.pro_searches_remaining,
        };
      }
      return user;
    });

    setSearchResult(updatedUsers);

    fetch(`${process.env.REACT_APP_NODE_URL}/admin/update-user`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        userID: userId,
        ...editedData,
      }),
    })
      .then(response => {
        if (response.ok) {
          console.log('User updated successfully');
        } else {
          console.error('Error updating user');
        }
      })
      .catch(error => console.error('Error updating user:', error));
    setEditableRow(null);
  }

  function handleInputChange(event, field) {
    setEditedData({
      ...editedData,
      [field]: event.target.value,
    });
  }

  useEffect(() => {
    console.log('Admin state changed, ', isAdmin);
  }, [isAdmin]);

  const tableCellStyle = {
    color: '#fff',
    fontWeight: 'bold',
  };
  if (!isAdmin) return null;

  function handleViewAsUser(user) {
    const url = `${window.location.origin}/user-view/${user._id}`;
    window.open(url, '_blank');
  }

  function handlePageChange(newPage) {
    setLoading(true);
    setPage(newPage);
  }

  return (
    <>
      {{
        maps: <MapEditor returnToHome={() => setCurrentSection('users')} />,
        'real-time-view': (
          <RealTimeView returnToHome={() => setCurrentSection('users')} />
        ),
      }[currentSection] || (
        <div style={{ padding: '2rem', paddingBottom: '1rem' }}>
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '2%',
              }}
            >
              <Typography
                sx={{
                  color: '#39585c',
                  fontSize: '1.5rem',
                  fontWeight: '700',
                }}
              >
                Admin Panel
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  gap: '1rem',
                }}
              >
                <Button
                  sx={{
                    backgroundColor: '#39585c',
                    '&:hover': {
                      backgroundColor: '#39585c',
                    },
                    color: '#fff',
                  }}
                  onClick={() => setCurrentSection('real-time-view')}
                >
                  Real Time View
                </Button>

                <Button
                  sx={{
                    backgroundColor: '#39585c',
                    '&:hover': {
                      backgroundColor: '#39585c',
                    },
                    color: '#fff',
                  }}
                  onClick={() => setCurrentSection('maps')}
                >
                  Map Editor
                </Button>

                <Button
                  sx={{
                    backgroundColor: '#39585c',
                    '&:hover': {
                      backgroundColor: '#39585c',
                    },
                    color: '#fff',
                  }}
                >
                  <Link
                    to='/run-reports'
                    style={{ color: '#fff', textDecoration: 'none' }}
                  >
                    Back to dashboard
                  </Link>
                </Button>
              </Box>
            </Box>

            <form
              onSubmit={handleSearchSubmit}
              style={{
                marginBottom: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TextField
                id='standard-full-width'
                label='Search'
                style={{ marginRight: '10px', width: '300px' }}
                size='small'
                placeholder='Search'
                InputLabelProps={{
                  shrink: true,
                }}
                value={search}
                onChange={handleSearchChange}
              />
              <Button
                variant='contained'
                type='submit'
                sx={{
                  backgroundColor: '#39585c',
                  '&:hover': {
                    backgroundColor: '#39585c',
                  },
                }}
              >
                Search
              </Button>
            </form>
            <TableContainer
              component={Paper}
              sx={{
                height: '43rem',
              }}
            >
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: '#39585c',
                      color: '#fff',
                    }}
                  >
                    <TableCell sx={tableCellStyle}>Name</TableCell>
                    <TableCell sx={tableCellStyle}>Email</TableCell>
                    <TableCell sx={tableCellStyle}>
                      Bulk Searches Remaining
                    </TableCell>
                    <TableCell sx={tableCellStyle}>
                      Pro Searches Remaining
                    </TableCell>
                    <TableCell sx={tableCellStyle}>Edit</TableCell>
                    <TableCell sx={tableCellStyle}>View as User</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <>
                      {Array.from({ length: 10 }, (_, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    searchResult.map(user => (
                      <TableRow key={user._id}>
                        <TableCell>
                          {user.firstname} {user.lastname}
                        </TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell
                          sx={{
                            border:
                              user.bulk_searches_remaining < 0 ||
                              isNaN(user.bulk_searches_remaining)
                                ? '1px solid red'
                                : '',
                          }}
                        >
                          {editableRow === user._id ? (
                            <TextField
                              value={editedData.bulk_searches_remaining}
                              size='small'
                              onChange={event =>
                                handleInputChange(
                                  event,
                                  'bulk_searches_remaining',
                                )
                              }
                            />
                          ) : (
                            user?.bulk_searches_remaining || 0
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            border:
                              user.pro_searches_remaining < 0 ||
                              isNaN(user.pro_searches_remaining)
                                ? '1px solid red'
                                : '',
                          }}
                        >
                          {editableRow === user._id ? (
                            <TextField
                              size='small'
                              value={editedData.pro_searches_remaining}
                              onChange={event =>
                                handleInputChange(
                                  event,
                                  'pro_searches_remaining',
                                )
                              }
                              sx={{
                                border:
                                  editedData.pro_searches_remaining < 0 ||
                                  isNaN(editedData.pro_searches_remaining)
                                    ? '1px solid red'
                                    : '1px solid #39585c',

                                borderRadius: '5px',
                              }}
                            />
                          ) : (
                            user?.pro_searches_remaining || 0
                          )}
                        </TableCell>
                        <TableCell>
                          {editableRow === user._id ? (
                            <Save
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleSaveClick(user._id)}
                            />
                          ) : (
                            <Edit
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleEditClick(user)}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant='contained'
                            sx={{
                              backgroundColor: '#39585c',
                              '&:hover': {
                                backgroundColor: '#39585c',
                              },
                              color: '#fff',
                            }}
                            onClick={() => {
                              handleViewAsUser(user);
                            }}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {loading ? (
                <Skeleton variant='rectangular' width={40} height={40} />
              ) : (
                <>
                  <Button
                    onClick={() => handlePageChange(1)}
                    disabled={page === 1}
                    sx={{
                      backgroundColor: page === 1 ? '#39585c' : '#fff',
                      color: page === 1 ? '#fff' : '#39585c',
                      '&:hover': {
                        backgroundColor: '#39585c',
                        color: '#fff',
                      },
                      '&:disabled': {
                        backgroundColor: '#39585c',
                        color: '#fff',
                      },
                    }}
                  >
                    1
                  </Button>
                  {page > 3 && (
                    <Button
                      onClick={() => handlePageChange(page - 5)}
                      sx={{
                        backgroundColor: '#fff',
                        color: '#39585c',
                        '&:hover': {
                          backgroundColor: '#39585c',
                          color: '#fff',
                        },
                      }}
                    >
                      ...
                    </Button>
                  )}
                  {Array.from(
                    { length: Math.min(5, totalPages - 2) },
                    (_, index) => {
                      const pageIndex = Math.max(2, page - 2) + index;
                      return (
                        <Button
                          key={pageIndex}
                          onClick={() => handlePageChange(pageIndex)}
                          disabled={page === pageIndex}
                          sx={{
                            backgroundColor:
                              page === pageIndex ? '#39585c' : '#fff',
                            color: page === pageIndex ? '#fff' : '#39585c',
                            '&:hover': {
                              backgroundColor: '#39585c',
                              color: '#fff',
                            },
                            '&:disabled': {
                              backgroundColor: '#39585c',
                              color: '#fff',
                            },
                          }}
                        >
                          {pageIndex}
                        </Button>
                      );
                    },
                  )}
                  {page < totalPages - 2 && (
                    <Button
                      onClick={() => handlePageChange(page + 5)}
                      sx={{
                        backgroundColor: '#fff',
                        color: '#39585c',
                        '&:hover': {
                          backgroundColor: '#39585c',
                          color: '#fff',
                        },
                      }}
                    >
                      ...
                    </Button>
                  )}
                  <Button
                    onClick={() => handlePageChange(totalPages)}
                    disabled={page === totalPages}
                    sx={{
                      backgroundColor: page === totalPages ? '#39585c' : '#fff',
                      color: page === totalPages ? '#fff' : '#39585c',
                      '&:hover': {
                        backgroundColor: '#39585c',
                        color: '#fff',
                      },
                      '&:disabled': {
                        backgroundColor: '#39585c',
                        color: '#fff',
                      },
                    }}
                  >
                    {totalPages}
                  </Button>
                </>
              )}
            </div>
          </>
        </div>
      )}
    </>
  );
}
