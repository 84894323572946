/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ClearIcon } from '@mui/x-date-pickers/icons';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ChevronRight } from 'lucide-react';
import { useEffect, useState } from 'react';
import Pagination from './components/Pagination';
import MapView from './MapView';
import { useAuth } from '../../../AuthProvider';

function RealTimeView({ returnToHome }) {
  const { getAccessToken } = useAuth();
  const [vestmaps, setVestmaps] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [userFilter, setUserFilter] = useState('');
  const [templateFilter, setTemplateFilter] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [viewType, setViewType] = useState('list'); // 'list' or 'map'
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const queryParams = new URLSearchParams({
      page,
      pageSize: 10,
      user: userFilter,
      template: templateFilter,
      startDate: startDate ? startDate.toISOString() : '',
      endDate: endDate ? endDate.toISOString() : '',
      search: search,
    });

    const response = await fetch(
      `${process.env.REACT_APP_NODE_URL}/admin/reports?${queryParams.toString()}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    );
    const data = await response.json();
    if (data.status === 'Success') {
      setVestmaps(data.vestmaps);
      setTotalPages(data.totalPages);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 30000);

    return () => clearInterval(interval);
  }, [page, userFilter, templateFilter, startDate, endDate, search]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          padding: '2rem',
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <Typography
              onClick={returnToHome}
              sx={{
                color: '#39585c',
                fontSize: '1.5rem',
                fontWeight: '700',
                cursor: 'pointer',
              }}
            >
              Admin Panel
            </Typography>
            <ChevronRight
              style={{ color: '#39585c', height: '1.5rem', width: '1.5rem' }}
            />
            <Typography
              sx={{
                color: '#39585c',
                fontSize: '1.5rem',
                fontWeight: '700',
                textDecoration: 'underline',
              }}
            >
              Real Time View
            </Typography>
          </Box>
          <Box>
            <Button
              variant='contained'
              sx={{
                backgroundColor: '#39585c',

                '&:hover': {
                  backgroundColor: '#39585c',
                },
              }}
              onClick={() => {
                setViewType(viewType === 'list' ? 'map' : 'list');
              }}
            >
              {viewType === 'list' ? 'Map View' : 'List View'}
            </Button>
          </Box>
        </Box>

        {{
          map: <MapView />,
        }[viewType] || (
          <>
            {/* Search Filter */}
            <TextField
              label='Search by Address'
              variant='outlined'
              value={search}
              onChange={e => setSearch(e.target.value)}
              sx={{ marginBottom: '1rem', width: '100%' }}
              InputProps={{
                endAdornment: search && (
                  <IconButton onClick={() => setSearch('')}>
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />

            {/* User Filter */}
            <TextField
              label='Search User by Name or Email'
              variant='outlined'
              value={userFilter}
              onChange={e => setUserFilter(e.target.value)}
              sx={{ marginBottom: '1rem', width: '100%' }}
              InputProps={{
                endAdornment: userFilter && (
                  <IconButton onClick={() => setUserFilter('')}>
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />

            {/* Template Filter */}
            <FormControl fullWidth sx={{ marginBottom: '1rem' }}>
              <InputLabel>Template</InputLabel>
              <Select
                value={templateFilter}
                onChange={e => setTemplateFilter(e.target.value)}
                label='Template'
              >
                <MenuItem value=''>All Templates</MenuItem>
                {vestmaps
                  .map(item => item?.templateID?.templateName)
                  .filter(
                    (value, index, self) =>
                      value && self.indexOf(value) === index,
                  )
                  .map(templateName => (
                    <MenuItem key={templateName} value={templateName}>
                      {templateName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {/* Date Range Filter */}
            <Box sx={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
              <DatePicker
                label='Start Date'
                value={startDate}
                onChange={date => setStartDate(date)}
                renderInput={params => <TextField {...params} fullWidth />}
              />
              <DatePicker
                label='End Date'
                value={endDate}
                onChange={date => setEndDate(date)}
                renderInput={params => <TextField {...params} fullWidth />}
              />
            </Box>

            {/* Table */}
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Address</TableCell>
                    <TableCell>Template Name</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Date Created</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading
                    ? Array.from(new Array(10)).map((_, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                        </TableRow>
                      ))
                    : vestmaps.map(item => (
                        <TableRow
                          key={item._id}
                          sx={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            const url = `${window.location.origin}/report/${item._id}`;
                            window.open(url, '_blank');
                          }}
                        >
                          <TableCell>{item?.addressInfo?.address}</TableCell>
                          <TableCell
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                width: 'fit-content',
                                padding: '0.15rem',
                                borderRadius: '0.5rem',
                                backgroundColor:
                                  item?.templateID?.theme?.backgroundColor,
                                color: item?.templateID?.theme?.color,
                              }}
                            >
                              {item?.templateID?.templateName}
                            </Box>
                          </TableCell>
                          <TableCell>{item.MongodbUserID.firstname}</TableCell>
                          <TableCell>{item.MongodbUserID.lastname}</TableCell>
                          <TableCell>{item.MongodbUserID.email}</TableCell>
                          <TableCell>
                            {new Date(item.createdAt).toLocaleDateString()}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Pagination Controls */}
            <Pagination
              currentPage={page}
              totalPages={totalPages}
              onPageChange={newPage => setPage(newPage)}
            />
          </>
        )}
      </Box>
    </LocalizationProvider>
  );
}

export default RealTimeView;
