import { Box } from '@mui/material';
import BlockNameHeading from './BlockNameHeading';
import GenericBlock from './GenericBlock';
import { formatNumber } from '../utils';

export default function RentBlock({ rentBlock }) {
  if (!rentBlock) return null;

  return (
    <Box
      sx={{
        flex: 1,
        paddingY: '1.25rem',
        paddingX: '1rem',
        borderRadius: '1rem',
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '8rem',
      }}
    >
      <BlockNameHeading title={rentBlock?.value['rent_grid_title']} />

      <Box
        sx={{
          fontSize: {
            xs: '0.9rem',
            sm: '1.3rem',
          },
          fontWeight: '700',
          lineHeight: '20px',
          textAlign: 'center',

          '@media (max-width: 600px)': {
            lineHeight: '1.1',
          },
        }}
      >
        {rentBlock?.value['rent_grid_desc']}
      </Box>
      <p>
        Rent from
        <a
          target={'_blank'}
          href={rentBlock?.value['rent_grid_quickview_url']}
          rel='noreferrer'
          style={{
            fontWeight: '500',
            color: '#4FA490',
          }}
        >
          {' '}
          Rent-O-Meter
        </a>
      </p>
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          width: '100%',
        }}
      >
        <GenericBlock
          sectionBlock={{
            title: 'Average',
            value: {
              average: rentBlock?.value['average']
                ? formatNumber(rentBlock?.value['average'])
                : 'N/A',
            },
            outfields: ['average'],
            prefix: '$',
          }}
          tooltipTitle={'Rent Average'}
        />
        <GenericBlock
          sectionBlock={{
            title: 'Median',
            value: {
              median: rentBlock?.value['median']
                ? formatNumber(rentBlock?.value['median'])
                : 'N/A',
            },
            outfields: ['median'],
            prefix: '$',
          }}
          tooltipTitle={'Rent Median'}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
          width: '100%',
        }}
      >
        <GenericBlock
          sectionBlock={{
            title: '25 ~ PERCENTILE',
            value: {
              percentile25: rentBlock?.value['percentile_25']
                ? formatNumber(rentBlock?.value['percentile_25'])
                : 'N/A',
            },
            outfields: ['percentile25'],
            prefix: '$',
          }}
          tooltipTitle={'Rent 25 Percentile'}
        />
        <GenericBlock
          sectionBlock={{
            title: '75 ~ PERCENTILE',
            value: {
              percentile75: rentBlock?.value['percentile_75']
                ? formatNumber(rentBlock?.value['percentile_75'])
                : 'N/A',
            },
            outfields: ['percentile75'],
            prefix: '$',
          }}
          tooltipTitle={'Rent 75 Percentile'}
        />
      </Box>
    </Box>
  );
}
