import React, { useState } from 'react';
import QuestionMarkSVG from './QuestionMarkSVG';
import data from '../../VestmapViewer/Overlay/data.json';
import Overlay from '../../VestmapViewer/Overlay';
import { Box, Typography } from '@mui/material';

export default function DiversificationBlock({
  title,
  blockValue,
  tooltipTitle,
}) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  const sectionInfoAvailable = data[tooltipTitle];

  const styles = {
    boxStyle: {
      borderBottom: '2px solid gray',
      display: 'flex',
      gap: '1rem',
      padding: '0.5rem',
    },
    typographyStyle: {
      fontFamily: 'Lato',
      fontSize: {
        xs: '0.9rem',
        sm: '1.2rem',
      },
      fontWeight: '600',
    },
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '1.25rem',
        alignItems: 'center',
        padding: '1.5rem',
        position: 'relative',
      }}
    >
      <Box sx={styles.boxStyle}>
        <Typography
          sx={{
            ...styles.typographyStyle,
          }}
        >
          {title}
        </Typography>

        {sectionInfoAvailable && (
          <Box
            onClick={() => {
              handleQuestionMarkClick(tooltipTitle);
              setIsOverlayVisible(true);
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            <QuestionMarkSVG />
          </Box>
        )}

        {activeOverlay === tooltipTitle && (
          <div
            style={{
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '16rem',
              zIndex: '1000',
            }}
          >
            <Overlay
              isVisible={isOverlayVisible}
              onClose={() => {
                setIsOverlayVisible(false);
                handleCloseOverlay();
              }}
              keyElement={tooltipTitle}
            />
          </div>
        )}
      </Box>
      <Box sx={styles.boxStyle}>
        <Typography
          sx={{
            ...styles.typographyStyle,
            fontWeight: '900',
          }}
        >
          {isNaN(blockValue) ? 'N/A' : blockValue}
        </Typography>
      </Box>
    </Box>
  );
}
