import { useState } from 'react';
import Overlay from '../../VestmapViewer/Overlay';
import { Box, Typography } from '@mui/material';
import BlockNameHeading from './BlockNameHeading';
import QuestionMarkSVG from './QuestionMarkSVG';
export default function NearestStores({ stores }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  if (!stores) return null;

  const style = {
    storeBox: {
      display: 'flex',
      gap: '1.25rem',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    },
    storeTitle: {
      fontFamily: 'Lato',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '20px',
      textAlign: 'left',
    },
  };

  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1.25rem',
        paddingX: '3.5rem',
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          paddingX: '1rem',
          paddingY: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <BlockNameHeading title='Nearest Stores' />

          <Box
            onClick={() => {
              handleQuestionMarkClick('Nearest Stores');
              setIsOverlayVisible(true);
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            <QuestionMarkSVG />
          </Box>

          {activeOverlay === 'Nearest Stores' && (
            <div
              style={{
                position: 'absolute',
                top: '0%',
                left: '0%',
                width: '16rem',
                zIndex: '1000',
              }}
            >
              <Overlay
                isVisible={isOverlayVisible}
                onClose={() => {
                  setIsOverlayVisible(false);
                  handleCloseOverlay();
                }}
                keyElement={'Nearest Stores'}
              />
            </div>
          )}
        </Box>

        {stores?.value?.StoreName.map((store, index) => (
          <Box key={index} sx={style.storeBox}>
            <Typography sx={style.storeTitle}>{store}</Typography>
            <Box
              onClick={() => {
                handleQuestionMarkClick(`Store ${index + 1}`);
                setIsOverlayVisible(true);
              }}
              sx={{
                cursor: 'pointer',
              }}
            >
              <QuestionMarkSVG />
            </Box>

            {activeOverlay === `Store ${index + 1}` && (
              <div
                style={{
                  position: 'absolute',
                  top: '0%',
                  left: '0%',
                  width: '16rem',
                  zIndex: '1000',
                }}
              >
                <Overlay
                  isVisible={isOverlayVisible}
                  onClose={() => {
                    setIsOverlayVisible(false);
                    handleCloseOverlay();
                  }}
                  keyElement={`Store ${index + 1}`}
                />
              </div>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
