import {
  Scale,
  Search,
  AutoAwesome as Sparkles,
  TrendingUp,
} from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState, useContext } from 'react';
import SearchBoxAndControls from './SearchBoxAndControls';
import { useAuth } from '../../../../AuthProvider';

export default function InitialSearchForm({
  handleSubmit,
  addressError,
  setAddressError,
  selectedMaps,
  onMapSelect,
  isLoading,

  //
  initialMode,
  onModeChange,
  analyzeAddress,
  onAnalyzeAddressChange,
  compareAddresses,
  onCompareAddressesChange,
  selectedStates,
  onSelectedStatesChange,
  selectedPersona,
  onSelectedPersonaChange,
  selectedMapOptions,
  onMapOptionChange,
  onCustomMapSearch,
  input,
  setInput,
}) {
  const { user } = useAuth();
  const [mode, setMode] = useState('analyze');
  const [messages, setMessages] = useState([]);
  const [compareInputs, setCompareInputs] = useState(['', '']);
  const [showThirdCompareInput, setShowThirdCompareInput] = useState(false);
  const [isDeepThinkMode, setIsDeepThinkMode] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  const [expandedMode, setExpandedMode] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const chatContainerRef = useRef(null);
  const inputRef = useRef(null);
  const compareInputRefs = useRef([null, null, null]);

  // Function buttons
  const functionButtons = [
    {
      label: 'Analyze',
      icon: TrendingUp,
      mode: 'analyze',
      tooltip: 'Analyze a specific property',
      disabled: false,
    },
    {
      label: 'Compare',
      icon: Scale,
      mode: 'compare',
      tooltip: 'Compare multiple properties',
      disabled: true,
    },
    {
      label: 'Discover',
      icon: Sparkles,
      mode: 'discover',
      tooltip: 'Find new investment opportunities',
      disabled: true,
    },
    {
      label: 'Research',
      icon: Search,
      onClick: () => {
        setInput('Research the real estate market in ');
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus();
            const length = inputRef.current.value.length;
            inputRef.current.setSelectionRange(length, length);
          }
        }, 50);
      },
      tooltip: 'Research an area or market trend',
      disabled: true,
    },
  ];

  // Handle mode change
  const handleModeChange = newMode => {
    setMode(newMode);
    onModeChange(newMode);

    if (expandedMode === newMode) {
      setExpandedMode(null);
    } else {
      setExpandedMode(newMode);

      // Focus on appropriate input when a mode is selected
      setTimeout(() => {
        if (newMode === 'compare') {
          if (compareInputRefs.current[0]) {
            compareInputRefs.current[0].focus();
          }
        } else if (inputRef.current) {
          inputRef.current.focus();

          // If it's analyze mode, show a helpful placeholder
          if (newMode === 'analyze') {
            if (input === '') {
              setInput(''); // Just to trigger a re-render with the new placeholder
            }
          }
        }
      }, 50);
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleCompareInputChange = (index, value) => {
    const newInputs = [...compareInputs];
    newInputs[index] = value;
    setCompareInputs(newInputs);
  };

  const handleAddThirdCompareInput = () => {
    setShowThirdCompareInput(true);
    setTimeout(() => {
      if (compareInputRefs.current[2]) {
        compareInputRefs.current[2].focus();
      }
    }, 50);
  };

  const toggleMode = () => {
    setIsDeepThinkMode(prev => !prev);
  };

  // Determine input placeholder based on active mode and agent mode
  const getInputPlaceholder = () => {
    if (isDeepThinkMode) {
      return 'Ask anything...';
    }

    if (hasSearched) {
      return 'Ask follow-ups about this address...';
    }

    if (mode === 'analyze') {
      return 'Enter a valid street address...';
    } else if (mode === 'compare') {
      return 'Enter first property address...';
    } else if (mode === 'discover') {
      return "Describe what you're looking for...";
    }

    return 'Message VestMap Agent...';
  };

  const suggestedAddresses = [
    '123 Main St, Anytown USA',
    '456 Oak Ave, Anytown USA',
    '789 Pine Ln, Anytown USA',
  ];
  const suggestedFollowUps = [
    'How are the crime scores calculated?',
    'What data sources are used?',
    'Compare the income and crime sections',
  ];
  const agentModeSuggestions = [
    'Graph the last three years of median income data',
    'Map the stores nearby',
    'Research infrastructure developments',
  ];

  // Main component return
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: { xs: '100%', md: '70%' },
        mx: 'auto',
      }}
    >
      {/* Initial View */}
      {!hasSearched && (
        <>
          {/* todo - add real username */}
          <Typography
            variant='h5'
            fontWeight='bold'
            align='center'
            sx={{ mb: 2, mt: 4 }}
          >
            Hello {user?.FirstName}, search an address to get started.
          </Typography>
          <Box sx={{ mb: 4 }}>
            <SearchBoxAndControls
              // Mode related props
              mode={mode}
              expandedMode={expandedMode}
              isDeepThinkMode={isDeepThinkMode}
              hasSearched={hasSearched}
              showThirdCompareInput={showThirdCompareInput}
              // Input related props
              input={input}
              setInput={setInput}
              compareInputs={compareInputs}
              // Map selection props
              selectedMapOptions={selectedMaps}
              onMapOptionChange={onMapSelect}
              onCustomMapSearch={() => {}} // Implement if needed
              // Event handlers
              handleCompareInputChange={handleCompareInputChange}
              handleSend={handleSubmit}
              handleModeChange={handleModeChange}
              toggleMode={toggleMode}
              handleAddThirdCompareInput={handleAddThirdCompareInput}
              // Data props
              functionButtons={functionButtons}
              suggestedAddresses={suggestedAddresses}
              suggestedFollowUps={suggestedFollowUps}
              agentModeSuggestions={agentModeSuggestions}
              // Utility props
              getInputPlaceholder={getInputPlaceholder}
              isLoading={isLoading}
            />
          </Box>
        </>
      )}

      {/* Search Box at the bottom after search */}
      {/* {hasSearched && (
        <Paper
          elevation={3}
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: 'background.paper',
            borderTop: 1,
            borderColor: 'divider',
            p: 2.5,
          }}
        >
          <Box sx={{ maxWidth: '48rem', mx: 'auto' }}>
            <SearchBoxAndControls />
          </Box>
        </Paper>
      )} */}
    </Box>
  );
}
