import React from 'react';
import { Box, Typography } from '@mui/material';
import GenericBlock from './GenericBlock';

export default function StatsBlock({ address, stats }) {
  const styles = {
    container: {
      flex: '0 0 100%',

      paddingY: '0.25rem',
      paddingX: '0rem',
      borderRadius: '0rem',
      display: 'flex',
      gap: '0rem',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '7rem',
    },
    address: {
      fontWeight: 'bold',
      fontSize: '0.2rem',
      textAlign: 'center',
    },
    row: {
      display: 'flex',
      gap: '0rem',
      width: '99%',
    },
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.address}>{address}</Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '0fr 1fr',
            sm: 'repeat(3, 1fr)',
          },
          justifyContent: 'center',
          gap: '0rem',
        }}
      >
        <GenericBlock
          sectionBlock={{
            title: 'Housing Affordability Index',
            value: {
              index: stats?.MSA_HAI_CY,
            },
            outfields: ['index'],
          }}
          tooltipTitle={'Housing Affordability Index'}
        />
        <GenericBlock
          sectionBlock={{
            title: 'Wealth Index',
            value: {
              index: stats?.MSA_WLTHINDXCY,
            },
            outfields: ['index'],
          }}
          tooltipTitle={'Wealth Index'}
        />
        <GenericBlock
          sectionBlock={{
            title: 'Population Growth Rate',
            value: {
              rate: stats?.MSA_POPGRWCYFY,
            },
            outfields: ['rate'],
          }}
          tooltipTitle={'Population Growth Rate'}
        />
        <GenericBlock
          sectionBlock={{
            title: 'Total Population',
            value: {
              population: stats?.MSA_TOTPOP19,
            },
            outfields: ['population'],
          }}
          tooltipTitle={'Total Population'}
        />
      </Box>
    </Box>
  );
}
