import { Box, Typography } from '@mui/material';
import React from 'react';
import BlockNameHeading from './BlockNameHeading';
import { formatNumber } from '../utils';

export default function Top5Block({ title, blockValue }) {
  const styles = {
    typography: {
      fontFamily: 'Lato',
      fontWeight: '400',
      fontSize: {
        xs: '0.8rem',
        sm: '1rem',
      },
    },
    value: {
      fontSize: {
        xs: '1.05rem',
        sm: '1.25rem',
      },
    },
    typographyStyle: {
      fontFamily: 'Lato',
      fontSize: {
        xs: '0.9rem',
        sm: '1.2rem',
      },
      fontWeight: '600',
    },
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '1.25rem',
        alignItems: 'center',
        padding: '1.5rem',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#F9F9F9',
          display: 'flex',
          paddingX: '1.5rem',
          paddingY: '.5rem',
          borderRadius: '1.2rem',
        }}
      >
        <BlockNameHeading title={title} />
      </Box>
      {!blockValue ? (
        <Typography
          sx={{
            ...styles.typographyStyle,
            fontWeight: '900',
          }}
        >
          N/A
        </Typography>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '.5rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '.5rem',
              paddingY: '.25rem',
              borderBottom: '2px solid gray',
            }}
          >
            <Typography sx={styles.typography}>
              {title.includes('Businesses')
                ? blockValue?.MSA_NUM_BUSINESS_TXT
                : blockValue?.MSA_NUM_EMP_TXT}
            </Typography>
            <Typography
              sx={{
                ...styles.typography,
                ...styles.value,
                fontWeight: '900',
              }}
            >
              {title.includes('Businesses')
                ? formatNumber(blockValue?.MSA_N01_BUS)
                : formatNumber(blockValue?.MSA_NUM_EMP)}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '.5rem',
              paddingY: '.25rem',
              borderBottom: '2px solid gray',
            }}
          >
            <Typography sx={styles.typography}>
              {title.includes('Businesses')
                ? blockValue?.MSA_NUM_BUSINESS_TXT_2
                : blockValue?.MSA_NUM_EMP_TXT_2}
            </Typography>
            <Typography
              sx={{
                ...styles.typography,
                fontWeight: '900',
                ...styles.value,
              }}
            >
              {title.includes('Businesses')
                ? formatNumber(blockValue?.MSA_N02_BUS)
                : formatNumber(blockValue?.MSA_NUM_EMP_2)}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '.5rem',
              paddingY: '.25rem',
              borderBottom: '2px solid gray',
            }}
          >
            <Typography sx={styles.typography}>
              {title.includes('Businesses')
                ? blockValue?.MSA_NUM_BUSINESS_TXT_3
                : blockValue?.MSA_NUM_EMP_TXT_3}
            </Typography>
            <Typography
              sx={{
                ...styles.typography,
                fontWeight: '900',
                ...styles.value,
              }}
            >
              {title.includes('Businesses')
                ? formatNumber(blockValue?.MSA_N03_BUS)
                : formatNumber(blockValue?.MSA_NUM_EMP_3)}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '.5rem',
              paddingY: '.25rem',
              borderBottom: '2px solid gray',
            }}
          >
            <Typography sx={styles.typography}>
              {title.includes('Businesses')
                ? blockValue?.MSA_NUM_BUSINESS_TXT_4
                : blockValue?.MSA_NUM_EMP_TXT_4}
            </Typography>
            <Typography
              sx={{
                ...styles.typography,
                fontWeight: '900',
                ...styles.value,
              }}
            >
              {title.includes('Businesses')
                ? formatNumber(blockValue?.MSA_N04_BUS)
                : formatNumber(blockValue?.MSA_NUM_EMP_4)}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '.5rem',
              paddingY: '.25rem',
              borderBottom: '2px solid gray',
            }}
          >
            <Typography sx={styles.typography}>
              {title.includes('Businesses')
                ? blockValue?.MSA_NUM_BUSINESS_TXT_5
                : blockValue?.MSA_NUM_EMP_TXT_5}
            </Typography>
            <Typography
              sx={{
                ...styles.typography,
                fontWeight: '900',
                ...styles.value,
              }}
            >
              {title.includes('Businesses')
                ? formatNumber(blockValue?.MSA_N05_BUS)
                : formatNumber(blockValue?.MSA_NUM_EMP_5)}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
