import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export default function HPIChart({ data }) {
  if (!data) return null;

  const prioriProps = [
    {
      stroke: '#4f7d70',
      dot: { r: 5, fill: 'white', stroke: '#659f8f' },
    },
    {
      stroke: '#b1cec6',
      dot: { r: 0 },
    },
    {
      stroke: '#80ffaa',
      dot: { r: 0 },
    },
  ];
  const assignPrioris = data => {
    const output = [];
    // Check if zip is not all 0
    const zipStatus = data.some(
      item => item['zip'] !== 0 && !(item['zip'] === undefined),
    );
    // Check if county is not all 0
    const countyStatus = data.some(
      item => item['county'] !== 0 && !(item['county'] === undefined),
    );
    // Check if tract is not all 0
    const tractStatus = data.some(
      item => item['tract'] !== 0 && !(item['tract'] === undefined),
    );

    if (tractStatus)
      output.push(prioriProps[0], prioriProps[1], prioriProps[2]);
    else if (zipStatus)
      output.push(prioriProps[1], prioriProps[0], prioriProps[2]);
    else if (countyStatus)
      output.push(prioriProps[2], prioriProps[1], prioriProps[0]);
    else output.push([...prioriProps]);
    return output;
  };
  const [tractProps, zipProps, countyProps] = assignPrioris(data);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const value = payload[0]?.payload;
      return (
        <div>
          <p className='label'>{`Year ${label}`}</p>
          {value.tract && <p className='value'>{`Tract: ${value.tract}`}</p>}
          {value.zip && <p className='value'>{`Zip: ${value.zip}`}</p>}
          {value.county && <p className='value'>{`County: ${value.county}`}</p>}
        </div>
      );
    }

    return null;
  };
  const isMobile = window.innerWidth <= 768;

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' fontSize={14} />
        {isMobile ? null : (
          <YAxis>
            <Label angle={-90} fontSize={6} position='insideLeft' />
          </YAxis>
        )}
        <Tooltip content={<CustomTooltip />} />
        <Legend
          iconType='rect'
          iconSize={20}
          margin={(0, 0, 0, -10)}
          verticalAlign='bottom'
        />
        <Line
          key={2}
          dataKey='tract'
          name='Tract'
          strokeWidth={3}
          {...tractProps}
        />
        <Line key={1} dataKey='zip' name='Zip' strokeWidth={3} {...zipProps} />
        <Line
          key={3}
          dataKey='county'
          name='County'
          strokeWidth={3}
          {...countyProps}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
