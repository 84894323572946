import HPIChart from './HPIChart';
export default function ChartBlock({ data }) {
  return (
    <>
      <div
        style={{
          height: '27rem',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <h2>HPI TRACT/ZIP INDEX</h2>
        <div
          style={{
            height: '27rem',
            width: '100%',
          }}
        >
          <HPIChart data={data} />
        </div>
      </div>
      <div
        style={{
          fontSize: '.75rem',
          lineHeight: '1.1',
          marginTop: '1rem',
          marginBottom: '1rem',
          paddingY: { xs: '0', sm: '1rem' },
          paddingX: { xs: '0', sm: '1.75rem' },
        }}
      >
        <p style={{ fontSize: '.75rem', lineHeight: '1.1' }}>
          <span
            style={{
              fontSize: '.75rem',
              lineHeight: '1.1',
              fontWeight: 'bold',
            }}
          >
            {' '}
            The Federal Housing Finance Agency (FHFA) House Price Index (HPI){' '}
          </span>
          is a weighted, repeat-sales index that measures the movement of house
          prices in the United States. The HPI is calculated with the average
          price changes in repeat sales or re-financings on the same properties.
          The FHFA HPI data set is built on tens of millions of sales and offers
          insights about price fluctuations at the national, census division,
          state, metro area, county, ZIP code, and census tract levels¹.
        </p>

        <p style={{ fontSize: '.75rem', lineHeight: '1.1' }}>
          <span style={{ fontSize: '.75rem', lineHeight: '1.1' }}>
            {' '}
            If an HPI value increased by 10% from 1990 to 1991{' '}
          </span>
          , it means that house prices increased by 10% during that period. The
          HPI base is 100: all subsequent years are a measurement of total
          appreciation since the base year. For example, an HPI value of 756
          taken in 2023 means that area has appreciated 656% (756-100=656%)
          since 1990 (1990 as the base year has a value of 100).
        </p>

        <p style={{ fontSize: '.75rem', lineHeight: '1.1' }}>
          <span style={{ fontSize: '.75rem', lineHeight: '1.1' }}>
            {' '}
            The background rate of appreciation is the expected annual
            appreciation{' '}
          </span>
          per year for a given given area. It is calculated by taking the total
          appreciation since the base year divided by the number of years.
        </p>
      </div>
    </>
  );
}
