import GenericBlock from './GenericBlock';
import { Box } from '@mui/material';
import BlockNameHeading from './BlockNameHeading';

export default function PriceAppreciation({
  blockValue,
  title,
  runningValues,
}) {
  // Check if section info is available in data.json
  // const sectionInfoAvailable = data['Demographic Group'];

  // * For Background Rate of Appreciation (1990 - present)
  // Calculate the year over year appreciation
  const calculateAppreciation = data => {
    if (!data) return null;
    const parsedData = {};

    Object.entries(data).filter(([key, value]) => {
      if (value === 0) return false;
      const year = key.split('_')[1];
      return (parsedData[year] = value);
    });

    const years = Object.keys(parsedData).sort((a, b) => b - a);

    const latestYear = years[0];
    const latestHPI = parsedData[latestYear];
    const firstYear = years[years.length - 1];
    const firstHPI = parsedData[firstYear];

    const numberOfYears = latestYear - firstYear;

    // Formula for appreciation
    const appreciation =
      ((latestHPI - firstHPI) / (numberOfYears * firstHPI)) * 100;

    return { value: appreciation.toFixed(2), since: firstYear };
  };

  // * For Price Appreciation Since 2011
  // Calculate the % change from the first year to 2011
  const calculateChange = data => {
    console.log('Calculate Change called');
    if (!data) return null;
    // data is an object
    const parsedData = {};
    console.log({ data });
    Object.entries(data).filter(([key, value]) => {
      if (value === 0) return false;
      const year = key.split('_')[1];
      if (parseInt(year) < 2011) return false;
      return (parsedData[year] = value);
    });

    const years = Object.keys(parsedData).sort((a, b) => b - a);

    const latestYear = years[0];
    const latestHPI = parsedData[latestYear];
    const firstYear = years[years.length - 1];
    const firstHPI = parsedData[firstYear];

    const change = (latestHPI - firstHPI) / firstHPI + 1;
    return { value: change.toFixed(2), since: firstYear };
  };

  console.log({ runningValues });

  return (
    <>
      {title === 'Price Appreciation Since 2011' && <HpiHeader />}
      <Box
        sx={{
          flexBasis: '100%',
          width: '100%',
          gap: { xs: '0', sm: '1.5rem' },
          paddingY: { xs: '0', sm: '1rem' },
          paddingX: { xs: '0', sm: '1.75rem' },
          borderRadius: '1rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: { xs: '0.5rem', sm: '1rem' },
            width: '100%',
          }}
        >
          <GenericBlock
            sectionBlock={{
              title: title,
              value: {
                tract: isNaN(
                  title === 'Price Appreciation Since 2011'
                    ? calculateChange(runningValues?.tract)?.value
                    : calculateAppreciation(runningValues?.tract)?.value,
                )
                  ? 'Unknown'
                  : title === 'Price Appreciation Since 2011'
                    ? calculateChange(runningValues?.tract)?.value
                    : calculateAppreciation(runningValues?.tract)?.value,
              },
              outfields: ['tract'],
              suffix: isNaN(
                title === 'Price Appreciation Since 2011'
                  ? calculateChange(runningValues?.tract)?.value
                  : calculateAppreciation(runningValues?.tract)?.value,
              )
                ? ''
                : title === 'Price Appreciation Since 2011'
                  ? 'x'
                  : '% per year',
            }}
            tooltipTitle={
              title === 'Price Appreciation Since 2011'
                ? 'PAS-Tract'
                : 'BRA-Tract'
            }
          />
          <GenericBlock
            sectionBlock={{
              title: title,
              value: {
                zip: isNaN(
                  title === 'Price Appreciation Since 2011'
                    ? calculateChange(runningValues?.zip)?.value
                    : calculateAppreciation(runningValues?.zip)?.value,
                )
                  ? 'Unknown'
                  : title === 'Price Appreciation Since 2011'
                    ? calculateChange(runningValues?.zip)?.value
                    : calculateAppreciation(runningValues?.zip)?.value,
              },
              outfields: ['zip'],
              suffix: isNaN(
                title === 'Price Appreciation Since 2011'
                  ? calculateChange(runningValues?.zip)?.value
                  : calculateAppreciation(runningValues?.zip)?.value,
              )
                ? ''
                : title === 'Price Appreciation Since 2011'
                  ? 'x'
                  : '% per year',
            }}
            tooltipTitle={
              title === 'Price Appreciation Since 2011' ? 'PAS-Zip' : 'BRA-Zip'
            }
          />
          <GenericBlock
            sectionBlock={{
              title: title,
              value: {
                county: isNaN(
                  title === 'Price Appreciation Since 2011'
                    ? calculateChange(runningValues?.county)?.value
                    : calculateAppreciation(runningValues?.county)?.value,
                )
                  ? 'Unknown'
                  : title === 'Price Appreciation Since 2011'
                    ? calculateChange(runningValues?.county)?.value
                    : calculateAppreciation(runningValues?.county)?.value,
              },
              outfields: ['county'],
              suffix: isNaN(
                title === 'Price Appreciation Since 2011'
                  ? calculateChange(runningValues?.county)?.value
                  : calculateAppreciation(runningValues?.county)?.value,
              )
                ? ''
                : title === 'Price Appreciation Since 2011'
                  ? 'x'
                  : '% per year',
            }}
            tooltipTitle={
              title === 'Price Appreciation Since 2011'
                ? 'PAS-County'
                : 'BRA-County'
            }
          />
        </Box>
      </Box>
    </>
  );
}

function HpiHeader() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        paddingY: { xs: '0', sm: '1rem' },
        paddingX: { xs: '0', sm: '1.75rem' },
      }}
    >
      <BlockNameHeading title={'Tract'} />
      <BlockNameHeading title={'Zip'} />
      <BlockNameHeading title={'County'} />
    </Box>
  );
}
