import { Typography } from '@mui/material';

function BlockNameHeading({ title }) {
  return (
    <Typography
      sx={{
        fontFamily: 'Lato',
        fontSize: {
          xs: '1.1rem',
          sm: '1.4rem',
        },
        lineHeight: '20px',
        fontWeight: 900,
        textAlign: 'center',
        color: '#39585C',
      }}
    >
      {title}
    </Typography>
  );
}

export default BlockNameHeading;
