import { useState } from 'react';
import Overlay from '../../VestmapViewer/Overlay';
import BlockNameHeading from './BlockNameHeading';
import QuestionMarkSVG from './QuestionMarkSVG';
import { Box, Typography } from '@mui/material';
import data from '../../VestmapViewer/Overlay/data.json';
import { formatNumber } from '../utils';

export default function ExpansionBlock({ sectionBlock, tooltipTitle }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  const sectionInfoAvailable =
    sectionBlock?.key === 'crime'
      ? data[sectionBlock?.outfields[0]]
      : data[tooltipTitle];

  if (!sectionBlock) return null;
  const styles = {
    text: {
      fontFamily: 'Lato',
      lineHeight: '1.5rem',
      textAlign: 'center',
    },
  };

  const growth = num => {
    if (num >= 1.44) {
      return 'Meteoric Growth';
    }
    if (num >= 0.73 && num <= 1.43) {
      return 'Growing';
    }
    if (num >= -0.25 && num <= 0.72) {
      return 'Stable';
    }
    if (num >= -1 && num <= -0.26) {
      return 'Declining';
    }
    if (num < -1) {
      return 'Steeply Declining';
    } else {
      return '';
    }
  };

  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: '#F9F9F9',
        paddingY: '1.25rem',
        paddingX: '1rem',
        borderRadius: '1rem',
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '8rem',
        position: 'relative',
      }}
    >
      <BlockNameHeading title={sectionBlock.title || ''} />
      <Typography
        sx={[
          styles.text,
          {
            fontSize: {
              xs: '1.2rem',
              sm: '1.4rem',
            },
            fontWeight: 900,
          },
        ]}
      >
        {sectionBlock?.value
          ? sectionBlock?.outfields
              .map(outfield => {
                if (!sectionBlock.value[outfield]) {
                  return 'N/A';
                }
                const prefix =
                  sectionBlock?.prefix === 'None'
                    ? ''
                    : sectionBlock?.prefix || '';
                const suffix =
                  sectionBlock?.suffix === 'None'
                    ? ''
                    : sectionBlock?.suffix ||
                      (typeof sectionBlock.value[outfield] === 'number'
                        ? '%'
                        : '');

                return `${prefix}${formatNumber(sectionBlock.value[outfield])}${suffix}`;
              })
              .join(' ')
          : 'N/A'}
      </Typography>
      <Typography
        sx={[
          styles.text,
          {
            fontSize: {
              xs: '0.8rem',
              sm: '1rem',
            },
            fontWeight: 400,
            color: '#39585C',
          },
        ]}
      >
        {growth(formatNumber(sectionBlock?.value[sectionBlock?.outfields[0]]))}
      </Typography>

      {sectionInfoAvailable && (
        <Box
          onClick={() => {
            console.log('I am clicked in expansion');
            handleQuestionMarkClick(tooltipTitle);
            setIsOverlayVisible(true);
          }}
          sx={{
            cursor: 'pointer',
          }}
        >
          <QuestionMarkSVG />
        </Box>
      )}

      {activeOverlay === tooltipTitle && (
        <div
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '16rem',
            zIndex: '1000',
          }}
        >
          <Overlay
            isVisible={isOverlayVisible}
            onClose={() => {
              setIsOverlayVisible(false);
              handleCloseOverlay();
            }}
            keyElement={tooltipTitle}
          />
        </div>
      )}
      {sectionBlock?.tooltip && <QuestionMarkSVG />}
    </Box>
  );
}
