import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import LiveMap from './LiveMap';

function MarkdownRenderer({ text, addressInfo, token }) {
  const segments = text.split(/(\[code\].*?\[\/code\])/g);

  // console.log({ testing: process.env.REACT_APP_DEV_MODE });

  return (
    <Box
      sx={{
        maxWidth: '100%',
        lineHeight: 1.5,
        color: '#757575',
      }}
    >
      {segments.map((segment, index) => {
        const mapIDMatch = segment.match(/\[code\]mapID=(\w+)\[\/code\]/);

        if (mapIDMatch) {
          const mapID = mapIDMatch[1];
          return (
            <Box
              key={index}
              sx={{
                marginBottom: '1rem',
                marginTop: '1rem',
                maxWidth: '100%',
                width: '100%',
                boxSizing: 'border-box',
                overflow: 'hidden',
              }}
            >
              {process.env.REACT_APP_DEV_MODE !== 'true' && (
                <LiveMap
                  webmapID={mapID}
                  token={token}
                  addressInfo={addressInfo}
                  defaultSettings={true}
                />
              )}
            </Box>
          );
        }

        // For non-code segments, render as normal markdown
        return (
          <ReactMarkdown
            key={index}
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]} // Add this line
            components={{
              h3: ({ node, ...props }) => (
                <h3 {...props} style={{ textAlign: 'left' }} />
              ),
              a: ({ node, ...props }) => (
                <a
                  {...props}
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    color: '#40c4aa',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                />
              ),
              // Table components styling (optional but recommended)
              table: ({ node, ...props }) => (
                <Paper sx={{ overflow: 'auto', margin: '1rem 0' }}>
                  <Table size='small' {...props} />
                </Paper>
              ),
              thead: ({ node, ...props }) => <TableHead {...props} />,
              tbody: ({ node, ...props }) => <TableBody {...props} />,
              tr: ({ node, ...props }) => <TableRow {...props} />,
              th: ({ node, ...props }) => (
                <TableCell
                  {...props}
                  sx={{
                    fontWeight: 'bold',
                    backgroundColor: '#f5f5f5',
                    color: '#424242',
                  }}
                />
              ),
              td: ({ node, ...props }) => <TableCell {...props} />,
            }}
          >
            {segment}
          </ReactMarkdown>
        );
      })}
    </Box>
  );
}

export default MarkdownRenderer;
