import { useState } from 'react';
import BlockNameHeading from './BlockNameHeading';
import { Typography, Box } from '@mui/material';
import QuestionMarkSVG from './QuestionMarkSVG';
import { formatNumber } from '../utils';
import Overlay from '../../VestmapViewer/Overlay';
import data from '../assets/data.json';

export default function GenericBlock({ sectionBlock, tooltipTitle }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  if (!sectionBlock) return null;
  const styles = {
    text: {
      fontFamily: 'Lato',
      lineHeight: {
        xs: '0.9rem',
        sm: '1.5rem',
      },
      textAlign: 'center',
    },
  };

  // for crime only
  const formatMultiple = value => {
    if (!value) return 'N/A';
    const value2 = value / 100;
    return `${value2}`;
  };

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  const sectionInfoAvailable =
    sectionBlock?.key === 'crime'
      ? data[sectionBlock?.outfields[0]]
      : data[tooltipTitle];

  return (
    <Box
      sx={{
        flex: {
          xs: '1 1 100%',
          sm: 1,
        },
        backgroundColor: '#F9F9F9',
        paddingY: {
          xs: '0.3rem',
          sm: '1.25rem',
        },
        paddingX: {
          xs: '0.75rem',
          sm: '1rem',
        },
        borderRadius: '1rem',
        display: 'flex',
        gap: {
          xs: '0.5rem',
          sm: '1rem',
        },
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: {
          xs: '6rem',
          sm: '8rem',
        },
        position: 'relative',
      }}
    >
      {!sectionBlock?.key?.includes('crime') && (
        <BlockNameHeading title={sectionBlock?.group || ''} />
      )}

      {sectionBlock?.key === 'demographics' &&
      sectionBlock?.outfields[0] === 'grade' ? (
        <BlockNameHeading title={sectionBlock?.title || ''} />
      ) : (
        <Typography
          sx={[
            styles.text,
            {
              fontSize: {
                xs: '0.9rem',
                sm: '1.1rem',
              },

              fontWeight: sectionBlock?.key === 'demographics' ? 900 : 500,
              color: '#39585C',
            },
          ]}
        >
          {sectionBlock.title}
        </Typography>
      )}

      <Typography
        sx={[
          styles.text,
          {
            fontSize: {
              xs: '1rem',
              sm: '1.4rem',
            },
            fontWeight: 900,
          },
        ]}
      >
        {sectionBlock?.key === 'crime' &&
          (sectionBlock?.value
            ? sectionBlock?.outfields
                .map(outfield => {
                  if (!sectionBlock.value[outfield]) {
                    return 'N/A';
                  }
                  const prefix =
                    sectionBlock?.prefix === 'None'
                      ? ''
                      : sectionBlock?.prefix || '';
                  const suffix =
                    sectionBlock?.suffix === 'None'
                      ? ''
                      : sectionBlock?.suffix || '';
                  return `${prefix}${isNaN(sectionBlock.value[outfield]) ? formatNumber(sectionBlock.value[outfield]) : formatMultiple(sectionBlock.value[outfield])}${suffix}`;
                })
                .filter(value => value !== 'N/A')
                .join(' ')
            : 'N/A')}

        {sectionBlock?.key !== 'crime' &&
          (sectionBlock?.value
            ? sectionBlock?.outfields
                .map((outfield, index) => {
                  if (!sectionBlock.value[outfield]) {
                    return 'N/A';
                  }
                  const prefix =
                    sectionBlock?.prefix === 'None'
                      ? ''
                      : sectionBlock?.prefix || '';
                  const suffix =
                    sectionBlock?.suffix === 'None'
                      ? ''
                      : sectionBlock?.suffix || '';

                  return `${prefix}${formatNumber(sectionBlock.value[outfield])}${suffix}`;
                })
                .join(' ')
            : 'N/A')}
      </Typography>

      {sectionBlock?.key === 'crime' && (
        <Typography
          sx={{
            fontFamily: 'Lato',
            lineHeight: '1.5rem',
            textAlign: 'center',
            fontSize: '1rem',
            fontWeight: 500,
          }}
        >
          National Average
        </Typography>
      )}

      {sectionInfoAvailable && (
        <Box
          onClick={() => {
            console.log('I am clicked');
            handleQuestionMarkClick(sectionBlock?.title);
            setIsOverlayVisible(true);
          }}
          sx={{
            cursor: 'pointer',
          }}
        >
          <QuestionMarkSVG />
        </Box>
      )}

      {(activeOverlay === sectionBlock?.title ||
        activeOverlay === tooltipTitle) && (
        <div
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            width: '16rem',
            zIndex: '1000',
          }}
        >
          <Overlay
            isVisible={isOverlayVisible}
            onClose={() => {
              setIsOverlayVisible(false);
              handleCloseOverlay();
            }}
            keyElement={
              sectionBlock?.key === 'crime'
                ? sectionBlock?.outfields[0]
                : tooltipTitle
            }
          />
        </div>
      )}

      {sectionBlock?.tooltip && <QuestionMarkSVG />}
    </Box>
  );
}
